import React, { useState, useRef } from 'react'

export default function RadioGroup({ item, currentData, updateFormData, handleClick }) {

    // console.log(currentData);
    const [state, setstate] = useState(currentData);
    const element = useRef();
    const handleState = (event) => {
        setstate(event.target.value);
        if (handleClick) {
            handleClick(event.target.value);
        }
    }


    return (
        <>
            {item && <div className={`${item.show === "false" ? "no-display" : ""} ${item.name}`} ref={element}>
                <p className={`question-header ${item.required === "true" ? "required" : ""}`}>{item.label}</p>
                {item.values.map((data, index) => {
                    return (
                        <label key={index} className="radio-label">
                            <input name={item.name} type="radio" required={index === 0 && item.required === "true" ? true : false} value={data} onChange={(event) => { updateFormData(event, item.label) }} checked={data === state ? true : false} onClick={handleState} />
                            <span className="radio-symbol">{data}</span>
                        </label>
                    )
                })}
                <p id={`error-message-${item.name}`} className="error-message no-display">{item.error}</p>

            </div>
            }
        </>
    )
}
