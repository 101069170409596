export default function fbsebreakup(caseNumber) {

    return `


    <html>

    <div class=WordSection1>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Hej, </span></p>
    
    <br />

    <p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Ditt ärende är nu registrerat med ärendenummer ${caseNumber}</span></p>

    <br />
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>För att vi ska kunna utvärdera ditt ärende
    måste vi ha följande dokument vid skilsmässa:</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>-Dokumentation som styrker att ni är folkbokförda på olika
    adresser sedan 30 dagar tillbaka</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>-Kopia av tingsrättens dom om skilsmässa </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>För att vi ska kunna utvärdera ditt ärende
    måste vi ha följande dokument vid separation:</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-Dokumentation
    som styrker att ni är folkbokförda på olika adresser sedan 30 dagar tillbaka</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-Dokumentation
    på gemensamma barn</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Om dokumenten inte har laddats upp i
    anmälningsformuläret eller om du behöver skicka dokumentation kan detta laddas
    upp via </span><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif'><a
    href="http://www.tilcrawford.no"><span style='background:#F8F9FA'>www.tilcrawford.no
    </span></a></span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'> </span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Alternativt kan dokumentation skickas per
    post till:</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Crawford &amp; Company (Norge) AS</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Postboks 556</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>1327 Lysaker</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'> </span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Så snart all nödvändig dokumentation har
    mottagits kommer rättshandledaren att göra en bedömning av ärendet.</span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'> </span></p>
    
    <p style='margin:0cm'><span lang=SV style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Vi har en bearbetningstid på 5 arbetsdagar
    från all nödvändig dokumentation som anges ovan har mottagits. Vid behov kan du
    kontakta oss via e-post: firmapost@crawco.no. Kom ihåg ärendenummer!</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>&nbsp;</span></p>
    
    <p style='margin:0cm'><i><span lang=SV style='font-size:9.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Crawford &amp; Company (Norge) AS är en
    databehandlare och behandlar personlig information om dig på uppdrag av
    personuppgiftsansvariga i samband med anmälan om skada. Den registeransvarige
    är AmTrust International Underwriters Limited, 40 Westland Row, Dublin 2,
    Irland. Syftet med behandlingen är att ta hand om försäkringsavtalet och göra
    korrekta uppgörelser. Du har tillgång till din personliga information och har
    rätt att korrigera felaktig information. Den personliga informationen är
    konfidentiell och kommer att raderas enligt reglerna om begränsning.
    Registrering av födelse nummer är nödvändigt för säker identifiering, kontroll
    i offentliga register och för att säkerställa korrekt rapportering till
    offentliga auktoriteter. Integritetsombud i Crawford &amp; Company (Norge) AS
    kan kontaktas på </span></i><span lang=SV style='font-size:9.0pt;font-family:
    "Calibri",sans-serif'><a href="mailto:compliance@crawco.no"><i><span
    style='background:#F8F9FA'>compliance@crawco.no </span></i></a></span></p>
    
    <p class=MsoNormal>&nbsp;</p>
    
    </div>
    
    </body>
    
    </html>
    

`}