export default function dvfiwork(caseNumber) {

    return `
    <html>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Hei,</span></p>
     
    <br />

    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Olemme vastaanottaneet korvaushakemuksesi, jota käsittelemme vahinkoviitteellä ${caseNumber}</span></p>
    
     <br />
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    style='font-size:11.0pt;font-family:"Calibri",sans-serif'>TARVITTAVAT
    ASIAKIRJAT TOISTAISEKSI VOIMASSAOLEVA 
    TYÖSUHDE:                                                 <br>
    - Jäljennös työnantajan irtisanomisilmoituksesta, josta käy ilmi työttömyyden
    syy;                                                                                                  <br>
    -Työsuhteen muoto ja se, kuinka kauan olet ollut kyseisen työnantajan
    palveluksessa.<br>
    -Jäljennös työ- ja elinkeinotoimiston (TE-toimisto) todistuksesta, josta
    ilmenee, että olet ilmoittautunut työnhakijaksi, sinulla on oikeus päivärahaan
    ja etsit aktiivisesti
    töitä.                                                                            
    <br>
    -Jäljennös Kansaneläkelaitoksen maksuerittelyistä siltä ajalta kun haet korvausta.<br>
    <br>
    
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Jos asiakirjoja ei
    ole ladattu korvaushakemukseen, voit tarvittaessa myöhemmin lähettää ne <a
    href="http://www.tilcrawford.no">www.tilcrawford.no</a><span style='color:black'>
    palvelun kautta.</span></span></p>
    
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Asiakirjat
    voi lähettää myös postitse
    osoitteella:                                                                                               
    <br>
    Crawford &amp; Company (Norway)
    AS                                                                                                                     
    <br>
    Postboks 556                                                                                              
                                                                  <br>
    1327 Lysaker                                                                                                                                                     
    <br>
    Norway</span></p>
    
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Voidaksemme
    jatkaa korvauskäsittelya, tarvitsemme kaikki yllä mainitut asiakirjat.</span></p>
    
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><span
    lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Hakemuksen
    käsittelyaika on 5 arkipäivää, hun meillä on kaikki tarvittavat tiedot ja
    asiakirjat. Meihin voi ottaa yhteyttä sähköpostitse: <a
    href="mailto:firmapost@crawco.no">firmapost@crawco.no</a><span
    style='color:black'>. Viittaa aina yllä olevaan vahinkoviitteeseen.</span></span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>&nbsp;</span></p>
    
    <p style='margin:0cm'><i><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Crawford &amp; Company (Norja) AS on
    henkilötietojen käsittelijä, joka käsittelee korvauksenhakijan henkilötietoja
    ilmoitettuun vahinkoasiaan liittyvän vakuutuksenantajan lukuun.
    Vakuutuksenantaja on AmTrust International Underwriters Limited, 40 Westland
    Row, Dublin 2, Irlanti. Henkilötietojen käsittelyn tarkoituksena on
    vakuutussopimuksen ja -korvausten hallinnointi. Korvauksenhakijalla on oikeus
    tarkastaa mitä häntä koskevia henkilötietoja käsitellään ja oikeus
    virheellisten tietojen korjaamiseen. Henkilötiedot ovat luottamuksellisia ja ne
    poistetaan vanhentumisaikojen mukaisesti. Henkilötunnuksen rekisteröinti on
    välttämätöntä oikean tunnistamisen, julkisten rekistereiden tarkistamisen ja
    viranomaisille oikean raportoinnin ylläpitämiseksi. Crawford &amp; Company
    (Norja) AS: n vaatimustenmukaisuudesta vastaavaan virkailijaan voi ottaa
    yhteyttä osoitteella compliance@crawco.no</span></i></p>
    
    <p class=MsoNormal>&nbsp;</p>
    
    </div>
    
    </body>
    
    </html>
    

`}