import React, { useEffect } from 'react';
import Breakup from './damageType/Breakup';
import Sickness from './damageType/Sickness';
import WorkCondition from './damageType/WorkCondition';
import FormNavigation from '../components/FormNavigation';


export default function Insurance({ handleFileUpload, updateFormData, formValid, handleStages, formData, updateCurrentState, formText, formFiles }) {



    useEffect(() => {
        updateCurrentState(2);
        // console.log(formData[formText.formType.name]);
    })

    return (
        <>
            {(formData === null || formData === undefined) && <a className="lead" href="/">Tilbake til hjemside</a>}
            {formText && <div>
                {/* {formData[formText.formType.name] === formText.formType.damageTypes[3] && <Death handleFileUpload={handleFileUpload} updateFormData={updateFormData} formData={formData} formValid={formValid} />} */}
                {formData[formText.formType.name] === formText.formType.damageTypes[0] && <WorkCondition handleFileUpload={handleFileUpload} updateFormData={updateFormData} formData={formData} formValid={formValid} {...formText} formFiles={formFiles} />}
                {formData[formText.formType.name] === formText.formType.damageTypes[1] && <Breakup handleFileUpload={handleFileUpload} updateFormData={updateFormData} formData={formData} formValid={formValid} {...formText} formFiles={formFiles} />}
                {formData[formText.formType.name] === formText.formType.damageTypes[2] && <Sickness handleFileUpload={handleFileUpload} updateFormData={updateFormData} formData={formData} formValid={formValid} {...formText} formFiles={formFiles} />}
                <FormNavigation finalStage={3} nextRoute={formValid ? "/kvittering" : "/forsikring"} prevRoute="/" currentStage={2} formValid={formValid} handleStages={handleStages} {...formText} />
            </div>}
        </>
    )
}
