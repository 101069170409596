export default function fbdksickness(caseNumber) {

    return `

<html>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Hej,</span></p>

<br />

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Din sag er nu registreret med sagsnummer ${caseNumber}</span></p>

<br />

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>For at vi kan vurdere din sag, har vi brug
for følgende dokumenter:</span></p>

<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>-Lægeerklæring, der viser, at du har været sygemeldt fra din
stilling og bekræfter sygdomsperiode, diagnose og sygdomsgrad</span></p>

<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>-Dokumentation for udbetalte sygedagpenge eller anden dokumentation, som viser udstrækningen af erstatning/løn fra din arbejdsgiver i sygemeldingsperioden for minimum 31 dage.</span></p>

<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>


<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Hvis dokumenterne ikke er uploadet i
ansøgningsformularen, eller hvis du skal sende dokumentation, kan dette
uploades via </span><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif'><a
href="http://www.tilcrawford.no"><span style='background:#F8F9FA'>www.tilcrawford.no</span></a></span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>&nbsp;</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Alternativt kan dokumentation sendes med
posten til:</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Crawford &amp; Company (Norge) AS</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Postboks 556</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>1327 Lysaker</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>&nbsp;</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Så snart al nødvendig dokumentation er
modtaget, foretager sagsbehandleren en vurdering af sagen.</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>&nbsp;</span></p>

<p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:#202124;background:#F8F9FA'>Vi har en behandlingstid på 5 arbejdsdage,
fra al nødvendig dokumentation, der er anført ovenfor, er modtaget. Hvis det er
nødvendigt, kan du kontakte os via e-mail: firmapost@crawco.no. Husk
sagsnummer!</span></p>

<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black'>&nbsp;</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm'><i><span
lang=DA style='font-size:9.0pt;font-family:"Calibri",sans-serif;color:black'>Crawford
&amp; Company (Norge) AS er en databehandler og behandler personlige
oplysninger om dig på vegne af den behandlingsansvarlige i forbindelse med en
anmeldelse af skade. Den behandlingsansvarlige er AmTrust International
Underwriters Limited, Westland Row 40, Dublin 2, Irland. Formålet med
behandlingen af dine data, er at varetage forsikringsaftalen og foretage
korrekte opgørelser. Du har ret til adgang til dine personlige oplysninger og
har ret til at få rettet forkerte oplysninger. De personlige oplysninger er
fortrolige og slettes i henhold til reglerne om forældelse. Registrering af
CPR-nr. er nødvendig for sikker identifikation, kontrol i offentlige registre
og for at sikre korrekt rapportering til offentlige myndigheder. Den
databehandlingsansvarlige i Crawford &amp; Company (Norge) AS kan kontaktes på </span></i><span
lang=DA style='font-size:9.0pt;font-family:"Calibri",sans-serif'><a
href="mailto:compliance@crawco.no"><i>compliance@crawco.no </i></a></span></p>

</div>

</body>

</html>

`
}