import React from 'react'

export default function Footer({ footer }) {
    return (
        <footer>
            <div className="contact">
                {footer.text && <p>{footer.text} </p>}
                {footer.tel.text && <><br /> {footer.tel.text}: <a href={`tel:${footer.tel.number}`}>{footer.tel.number}</a><br /></>}
                {footer.email.text}: <a href={`mailto:${footer.email.value}`}>{footer.email.value}</a>
            </div>
        </footer>
    )
}
