import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import './App.scss';
// import '../src/js/chat';
import jsonSource from './js/json-source';
import { getCaseNumber, postData, postAttchment, getToken, getReceipt } from './js/api-service';
import Insurance from './components/Insurance';
import Receipt from './components/Receipt';
import Header from './components/Header';
import Footer from './components/Footer';
import Logo from './components/custom/Logo';
import SetDamageType from './components/SetDamageType';


function App() {
  const [formText, setformText] = useState();
  const [formData, setformData] = useState();
  const [formFiles, setformFiles] = useState();
  const [currentStage, setCurrentStage] = useState(1);
  const [formValid, setFormValid] = useState();
  const [formSubmitted, setFormSubmitted] = useState("");
  const [receiptInfo, setReceiptInfo] = useState();
  const [language, setLanguage] = useState('');
  const [subject, setSubject] = useState('');
  const [languagePrefix, setLanguagePrefix] = useState('');
  const [insuranceCategory, setInsuranceCategory] = useState('');
  const [replySubject, setReplySubject] = useState('');
  const [waitingText, setWaitingText] = useState('');
  const [replyBody, setReplyBody] = useState('');
  const [isIE, setIsIE] = useState(false);
  const [refreshMessage, setRefreshMessage] = useState('');
  const [session, setSession] = useState('');
  const formElement = useRef();

  const browser = window.navigator.userAgent;
  const msie_old = browser.indexOf("MSIE ");
  const msie_new = browser.indexOf('Trident/');

  if (msie_old > 0 || msie_new > 0) {
    setIsIE(true);
  }

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get('code');
    let urlSession = urlParams.get('session');

    if (lang && urlSession) {
      lang = lang.replaceAll("\"", "");
      if (jsonSource()[lang] !== undefined) {
        setLanguage(lang);
        let detectReply = lang.slice(0, 2);
        if (detectReply === "kk" || detectReply === "rl") {
          detectReply = "fb"
        }
        setInsuranceCategory(detectReply);
      }
      else
        setLanguage("unSet");
    } else {
      setLanguage("unSet");
    }

    if (urlSession) {
      urlSession = urlSession.replaceAll("\"", "");
      if (session === '')
        getValidToken(urlSession);
    } else {
      setSession("unSet");
    }

    if (language === "unSet" && session === "unSet") {
      setformText('unSet');
    } else if ((jsonSource()[language] === undefined && language !== '') && session !== '') {
      setformText('unSet');
    } else {
      setformText(jsonSource()[language]);

      if (language === "norsk") {
        setReplySubject(jsonSource()["no"]);
        setLanguagePrefix("no");
      }
      else {
        let st = language.substr(2, 4);
        st = st === "fs" ? "se" : st;
        setReplySubject(jsonSource()[st]);
        setLanguagePrefix(st);
      }
    }

    refreshPage();
  }, [session])

  const getValidToken = async (urlSession) => {
    try {
      const data = await getToken(urlSession);
      if (data.message === "Valid token") {
        setSession(data.value);
      }
      else if (data.message === "Session Not Found") {
        setSession("unSet");
      } else {
        setSession("unSet"); // Todo: Use another error
      }
    } catch (err) {
      setSession("unSet");
    }
  }

  useEffect(() => {
    updateFormValidity();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (currentStage === 3) {
      updateSubject();
    }
  }, [currentStage])

  const ignoreTimeOffset = () => {
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
    const localIsoTime = (new Date(Date.now() - timeZoneOffset)).toISOString().slice(0, -1);
    return localIsoTime;
  }

  const handleSubmit = async (event) => {

    event.preventDefault();
    handleStages(event, true);
    let countAttachment = 0;

    // console.log(formFiles[formText.formType.name])

    if (formElement.current.checkValidity() && event.type === "submit" && currentStage === 3) {

      setWaitingText(replySubject["waitingtext"]);
      let fileList = formFiles[formFiles[formText.formType.name]];
      setFormSubmitted("Loading")
      const caseNumber = await getCaseNumber(session);
      let replyBodyText = await updateReply(caseNumber.Value);

      if (replyBodyText !== '') {
        if (caseNumber.message === "New case") {

          let newSubject = `${subject} ${caseNumber.value}`
          setSubject(newSubject);

          for (const file in fileList) {
            countAttachment += fileList[file].length;
          }
          // console.log(formFiles[formFiles[formText.formType.name]]);

          const body = {
            Body: JSON.stringify(receiptInfo),
            CountAttachment: countAttachment,
            InternalComment: session,
            Claimid: caseNumber.value
          }

          const postdata = await postData(session, body);

          if (postdata.message === "Sent succesfully") {
            let attachmentResult; // "File"
            for (const attachments in fileList) {
              if (fileList[attachments].length !== 0) {
                let files = Array.from(fileList[attachments]);
                for (let index = 0; index < files.length; index++) {
                  const attachment = files[index];
                  attachmentResult = await postAttchment(session, attachment);
                }
              }
            }

            const receipt = {
              DelegateTo: 1006, // if test, 740
              DateInserted: ignoreTimeOffset(),
              DateReceived: ignoreTimeOffset(),
              MailFrom: "firmapost@crawco.no",     // "Webskjema@crawco.no",
              MailTo: postdata.value,//Emailaddress som kommer i retur fra maildata endepunkt,
              Mailsubject: `${subject} ${caseNumber.value}`,                                                                                                                                               //kanskje definert i kvitteringstekst?
              Body: replyBodyText,//HTML string av kvitteringsteksten,
              Direction: 'Out',
              DocType: "Webskjema",
              Client: "bnportal", //if test, dummyskade
              Claimid: caseNumber.value,
              SystemID: 'LCS',
              BodyFormat: 2,
              InternalComment: `kvittering ${session}`
            }

            await getReceipt(session, receipt);
            setFormSubmitted("Submitted");

          } else {
            setFormSubmitted("Fail");
          }
          //setFormSubmitted("Submitted");
        }
      } else {
        setFormSubmitted("Fail")
      }
    } else {
      // setFormSubmitted("Fail")
    }
  };



  const handleStages = (event, submitted = false) => {
    if (event.target.id === "nextButton" || submitted === true) {
      if (!formElement.current.checkValidity()) {
        document.querySelector('.form-error').classList.remove('no-display')
        let list = formElement.current.querySelectorAll("[required]");
        if (list) {
          [...list].forEach(element => {
            if (!element.checkValidity()) {
              document.getElementById(`error-message-${element.name}`).classList.remove('no-display');
            }
          });
        }
      } else {
        updateFormValidity();
      }
    } else {
      updateFormValidity();
    }
  };


  const updateFormData = (event, label = "", filename = false, files) => {

    // let data = { ...formData, [event.target.name]: event.target.value }; event.target.type=file
    if (!filename) {
      if (formData && formData[formText.formType.name] && event.target.name !== formText.formType.name) {
        setformData({ ...formData, [formData[formText.formType.name]]: { ...formData[formData[formText.formType.name]], [event.target.name]: event.target.value } });
        // updateSubject();
        if (label !== "")
          setReceiptInfo({ ...receiptInfo, [formData[formText.formType.name]]: { ...receiptInfo[receiptInfo[formText.formType.name]], [event.target.name]: { name: label, value: event.target.value } } });
      } else {
        setformData({ ...formData, [event.target.name]: event.target.value });
        setformFiles({ ...formFiles, [event.target.name]: event.target.value });
        setReceiptInfo({ ...receiptInfo, [event.target.name]: event.target.value });
      }

      if (event.target.checkValidity()) {
        if (event.target.required === true) {
          document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
        }
        if (event.target.type === "radio") {
          let radio = document.querySelector(`[name = ${event.target.name}]`);
          if (radio.required === true) {
            document.getElementById(`error-message-${event.target.name}`).classList.add('no-display');
          }
        }
      }
      updateFormValidity();

    } else {
      updateFiles(label, filename, files);
    }
  }

  const updateFiles = (label, name, files) => {
    setformFiles({ ...formFiles, [formFiles[formText.formType.name]]: { ...formFiles[formFiles[formText.formType.name]], [label]: files } });
    // console.log(formText.formType.name);
  }

  const updateFormValidity = () => {
    if (formText && formText !== "unSet") {
      setFormValid(formElement.current.checkValidity());
    }
  }
  const setFormValidity = (bool) => {
    setFormValid(bool);
  }

  const updateCurrentState = (currentstate) => {
    setCurrentStage(currentstate);
    updateFormValidity();
  }

  const updateSubject = () => {
    if (formData[formText.formType.name] === formText.formType.damageTypes[0]) {
      setSubject(replySubject["work"]["replytext"]["subjecttext"]);

    }
    else if (formData[formText.formType.name] === formText.formType.damageTypes[1]) {
      setSubject(replySubject["breakup"]["replytext"]["subjecttext"]);
    }
    else {
      setSubject(replySubject["sickness"]["replytext"]["subjecttext"]);
    }
  }

  const updateReply = async (casenumber) => {

    return new Promise(resolve => {

      let body = '';
      let replyBodyText = '';

      if (formData[formText.formType.name] === formText.formType.damageTypes[0]) {
        setSubject(replySubject["work"]["replytext"]["subjecttext"]);
        body = `${insuranceCategory}${languagePrefix}work`;
        replyBodyText = jsonSource()[body](casenumber);
        setReplyBody((jsonSource()[body](casenumber)));
        // console.log(body, jsonSource()[body](casenumber));
      }
      else if (formData[formText.formType.name] === formText.formType.damageTypes[1]) {
        setSubject(replySubject["breakup"]["replytext"]["subjecttext"]);
        body = `${insuranceCategory}${languagePrefix}breakup`;
        replyBodyText = jsonSource()[body](casenumber);
        setReplyBody((jsonSource()[body](casenumber)));
        // console.log(body);
      }
      else {
        setSubject(replySubject["sickness"]["replytext"]["subjecttext"]);
        body = `${insuranceCategory}${languagePrefix}sickness`;
        replyBodyText = jsonSource()[body](casenumber);
        setReplyBody((jsonSource()[body](casenumber)));
        // console.log(body);
      }
      resolve(replyBodyText);

    })

  }

  const refreshPage = () => {
    setTimeout(() => {
      if ((formText === undefined || formText === null) && (formSubmitted !== "Submitted" || formSubmitted !== "Loading")) {
        setRefreshMessage("It looks like there is a technical fail. Please make sure that your browser is up to date and then refresh this page!");
      }
    }, 5000);
  }

  // const TmpInfoText = () => {
  //   return (<p>
  //     Hei, <br />
  //     Vi opplever for tiden svært stor pågang og har lengre saksbehandlingstid enn normalt, for tiden ca. 6 uker.
  //     Alle spørsmål om din forsikringssak kan rettes til <a href="tel:004767552500">+47 67 55 25 00</a> (tastevalg 2) eller til <a href="mailto:firmapost@crawco.no">firmapost@crawco.no</a> merket med ditt saksnummer.
  //     <br />Takk for tålmodigheten.
  //   </p>)
  // }

  return (
    <>
      {(refreshMessage !== '' && (formText === undefined || formText === null)) && <h4 className="alert-message">{refreshMessage}</h4>}
      {!isIE && (!session || !language || formSubmitted === "Loading") && (session !== "unSet" || language !== "unSet") && <div className="loading">{waitingText !== '' && <h4 className="normal-header padding-small">{waitingText}</h4>}</div>}
      {!isIE && (formText === "unSet" || session === "unSet" || language === "unSet" || formSubmitted === "Fail") && <div className="form-container response-wrapper"><Logo /><h4 className="normal-header">Fail</h4></div>}
      {formSubmitted === "Submitted" && <div className="form-container response-wrapper"><Logo /><h4 className="normal-header" aria-live="assertive">{subject}</h4></div>}
      {(formText && formText !== "unSet" && session !== "unSet") && formSubmitted === "" && <>
        <Router>
          {formSubmitted !== "Submitted" && <>
            <Header finalStage={3} currentStage={currentStage} handleStages={handleStages} formValid={formValid} {...formText} />
            <main>
              <form className="form-container" ref={formElement} onSubmit={event => handleSubmit(event)} noValidate>
                <Switch>
                  <Route exact path="/">
                    <SetDamageType updateFormData={updateFormData}  {...formData} formValid={formValid} handleStages={handleStages} updateCurrentState={updateCurrentState} formText={formText} />
                  </Route>
                  <Route path="/forsikring">
                    <Insurance formData={formData} updateFormData={updateFormData} formValid={formValid} handleStages={handleStages} formElement={formElement} updateCurrentState={updateCurrentState} formText={formText} formFiles={formFiles} />
                  </Route>
                  <Route path="/kvittering">
                    <Receipt formData={formData} updateCurrentState={updateCurrentState} handleSubmit={handleSubmit} updateFormData={updateFormData} formText={formText} receiptInfo={receiptInfo} />
                  </Route>
                </Switch>
                {/* <FormNavigation finalStage={3} nextRoute={nextRoute} prevRoute={prevRoute} currentStage={currentStage} handleStages={handleStages} formValid={formValid} /> */}
              </form >
            </main>
          </>}
          <Footer {...formText} />
        </Router>
      </>
      }
    </>
  );
}

export default App;
