import React from 'react';
import { useState, useEffect } from 'react';
import FileList from '../custom/FileList';
import RadioGroup from '../custom/RadioGroup';

export default function WorkCondition({ updateFormData, formData, work, formType, formFiles }) {

    const { condition, extra, document } = work;
    const { information } = extra;
    const { docs } = document;
    const data = formData[formData[formType.name]];

    const [stillUnemployed, setStillUnemployed] = useState();
    const [fixedEmployee, setFixedEmployee] = useState();
    // const [registerAtNav, setRegisterAtNav] = useState();
    const [compensationFor, setCompensationFor] = useState();

    useEffect(() => {
        if (data && condition && condition.stillUmemployed)
            setStillUnemployed(data[condition.stillUmemployed.name]);
    }, [condition]);

    useEffect(() => {
        if (data && condition && condition.fixEmployee)
            setFixedEmployee(data[condition.fixEmployee.name]);
    }, [condition]);

    useEffect(() => {
        if (data && information && information.earlierCompensation)
            setCompensationFor(data[information.earlierCompensation.name])
    }, [information])

    const handleStillUnemployed = (value) => {
        setStillUnemployed(value)
    }

    const handleFixedEmployee = (value) => {
        setFixedEmployee(value);
    }

    const handleCompensationFor = (value) => {
        setCompensationFor(value)
    }


    return (
        <>
            {work && <div>
                <h2 className="large-header">{work.mainHeader}</h2>

                <h4 className="normal-header">{condition.header}</h4>

                {condition.lastEmployee.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${condition.lastEmployee.required === "true" ? "required" : ""}`}> {condition.lastEmployee.label}</span>
                        <input name={condition.lastEmployee.name} value={data && data[condition.lastEmployee.name] ? data[condition.lastEmployee.name] : ""} type="text" placeholder="" required={condition.lastEmployee.required === "true" ? true : false} onChange={(event) => { updateFormData(event, condition.lastEmployee.label) }} />
                    </label>
                    <p id={`error-message-${condition.lastEmployee.name}`} className="error-message no-display">{condition.lastEmployee.error}</p>
                </>}

                {condition.dateOfEmployment.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${condition.dateOfEmployment.required === "true" ? "required" : ""}`}> {condition.dateOfEmployment.label}</span>
                        <input name={condition.dateOfEmployment.name} type="date" min={condition.dateOfEmployment.min ? condition.dateOfEmployment.min : ""} placeholder="dd.mm.yyyy" value={data && data[condition.dateOfEmployment.name] ? data[condition.dateOfEmployment.name] : ""} required={condition.dateOfEmployment.required === "true" ? true : false} onChange={(event) => { updateFormData(event, condition.dateOfEmployment.label) }} />
                    </label>
                    <p id={`error-message-${condition.dateOfEmployment.name}`} className="error-message no-display">{condition.dateOfEmployment.error}</p>
                </>}

                {condition.dateWarningRedundancy.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${condition.dateWarningRedundancy.required === "true" ? "required" : ""}`}> {condition.dateWarningRedundancy.label}</span>
                        <input name={condition.dateWarningRedundancy.name} type="date" min={condition.dateWarningRedundancy.min ? condition.dateWarningRedundancy.min : ""} placeholder="dd.mm.yyyy" value={data && data[condition.dateWarningRedundancy.name] ? data[condition.dateWarningRedundancy.name] : ""} required={condition.dateWarningRedundancy.required === "true" ? true : false} onChange={(event) => { updateFormData(event, condition.dateWarningRedundancy.label) }} />
                    </label>
                    <p id={`error-message-${condition.dateWarningRedundancy.name}`} className="error-message no-display">{condition.dateWarningRedundancy.error}</p>
                </>}

                {condition.reasonForRedundancyOrQuit.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${condition.reasonForRedundancyOrQuit.required === "true" ? "required" : ""}`}> {condition.reasonForRedundancyOrQuit.label}</span>
                        <textarea name={condition.reasonForRedundancyOrQuit.name} onChange={(event) => { updateFormData(event, condition.reasonForRedundancyOrQuit.label) }} value={data && data[condition.reasonForRedundancyOrQuit.name] ? data[condition.reasonForRedundancyOrQuit.name] : ""} required={condition.reasonForRedundancyOrQuit.required === "true" ? true : false}  ></textarea>
                    </label>
                    <p id={`error-message-${condition.reasonForRedundancyOrQuit.name}`} className="error-message no-display">{condition.reasonForRedundancyOrQuit.error}</p>
                </>}

                {condition.lastDayDate.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${condition.lastDayDate.required === "true" ? "required" : ""}`}> {condition.lastDayDate.label}</span>
                        <input name={condition.lastDayDate.name} type="date" min={condition.lastDayDate.min ? condition.lastDayDate.min : ""} placeholder="dd.mm.yyyy" value={data && data[condition.lastDayDate.name] ? data[condition.lastDayDate.name] : ""} onChange={(event) => { updateFormData(event, condition.lastDayDate.label) }} required={condition.lastDayDate.required === "true" ? true : false} />
                    </label>
                    <p id={`error-message-${condition.lastDayDate.name}`} className="error-message no-display">{condition.lastDayDate.error}</p>
                </>}


                <RadioGroup item={condition.fixEmployee} currentData={data && data[condition.fixEmployee.name]} updateFormData={updateFormData} handleClick={handleFixedEmployee} />

                {fixedEmployee === "Nei" &&
                    <div  >
                        <RadioGroup item={condition.selfEmployed} currentData={data && data[condition.selfEmployed.name]} updateFormData={updateFormData} />
                    </div>
                }

                <RadioGroup item={condition.selfEmployedProfessional} currentData={data && data[condition.selfEmployedProfessional.name]} updateFormData={updateFormData} />

                <RadioGroup item={condition.registerAtNav} currentData={data && data[condition.registerAtNav.name]} updateFormData={updateFormData} />

                <div >
                    {condition.dateOfDayMoneyFromNav.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${condition.dateOfDayMoneyFromNav.required === "true" ? "required" : ""}`}> {condition.dateOfDayMoneyFromNav.label}</span>
                            <input name={condition.dateOfDayMoneyFromNav.name} type="date" min={condition.dateOfDayMoneyFromNav.min ? condition.dateOfDayMoneyFromNav.min : ""} placeholder="dd.mm.yyyy" value={data && data[condition.dateOfDayMoneyFromNav.name] ? data[condition.dateOfDayMoneyFromNav.name] : ""} required={condition.dateOfDayMoneyFromNav.required === "true" ? true : false} onChange={(event) => { updateFormData(event, condition.dateOfDayMoneyFromNav.label) }} />
                        </label>
                        <p id={`error-message-${condition.dateOfDayMoneyFromNav.name}`} className="error-message no-display">{condition.dateOfDayMoneyFromNav.error}</p>
                    </>}
                </div>


                <RadioGroup item={condition.stillUmemployed} currentData={data && data[condition.stillUmemployed.name]} updateFormData={updateFormData} handleClick={handleStillUnemployed} />

                {stillUnemployed === "Nei" &&
                    <div >
                        {condition.dateOfReemployed.show !== "false" && <>
                            <label className="input-label">
                                <span className={`${condition.dateOfReemployed.required === "true" ? "required" : ""}`}> {condition.dateOfReemployed.label}</span>
                                <input name={condition.dateOfReemployed.name} type="date" min={condition.dateOfReemployed.min ? condition.dateOfReemployed.min : ""} placeholder="dd.mm.yyyy" value={data && data[condition.dateOfReemployed.name] ? data[condition.dateOfReemployed.name] : ""} required={condition.dateOfReemployed.required === "true" ? true : false} onChange={(event) => { updateFormData(event, condition.dateOfReemployed.label) }} />
                            </label>
                            <p id={`error-message-${condition.dateOfReemployed.name}`} className="error-message no-display">{condition.dateOfReemployed.error}</p>
                        </>}
                    </div>
                }


                <h4 className="normal-header">{extra.header}</h4>

                <RadioGroup item={information.earlierCompensation} currentData={data && data[information.earlierCompensation.name]} updateFormData={updateFormData} handleClick={handleCompensationFor} />

                {compensationFor === "Ja" && <div>
                    <RadioGroup item={information.compensationFor} currentData={data && data[information.compensationFor.name]} updateFormData={updateFormData} />

                    {information.earlierstart.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${information.earlierstart.required === "true" ? "required" : ""}`}> {information.earlierstart.label}</span>
                            <input name={information.earlierstart.name} type="date" min={information.earlierstart.min ? information.earlierstart.min : ""} placeholder="dd.mm.yyyy" value={data && data[information.earlierstart.name] ? data[information.earlierstart.name] : ""} required={information.earlierstart.required === "true" ? true : false} onChange={(event) => { updateFormData(event, information.earlierstart.label) }} />
                            <p id={`error-message-${information.earlierstart.name}`} className="error-message no-display">{information.earlierstart.error}</p>
                        </label>
                    </>}


                    {information.earlierstopp.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${information.earlierstopp.required === "true" ? "required" : ""}`}> {information.earlierstopp.label}</span>
                            <input name={information.earlierstopp.name} type="date" min={information.earlierstopp.min ? information.earlierstopp.min : ""} placeholder="dd.mm.yyyy" value={data && data[information.earlierstopp.name] ? data[information.earlierstopp.name] : ""} required={information.earlierstopp.required === "true" ? true : false} onChange={(event) => { updateFormData(event, information.earlierstopp.label) }} />
                            <p id={`error-message-${information.earlierstopp.name}`} className="error-message no-display">{information.earlierstopp.error}</p>
                        </label>
                    </>}

                </div>
                }

                {information.extraInformation.show !== "false" && <>
                    <label className="input-label">
                        <span className={`${information.extraInformation.required === "true" ? "required" : ""}`}> {information.extraInformation.label}</span>
                        <textarea name={information.extraInformation.name} value={data && data[information.extraInformation.name] ? data[information.extraInformation.name] : ""} required={information.extraInformation.required === "true" ? true : false} onChange={(event) => { updateFormData(event, information.extraInformation.label) }}></textarea>
                        <p id={`error-message-${information.extraInformation.name}`} className="error-message no-display">{information.extraInformation.error}</p>
                    </label>
                </>}



                <h4 className="normal-header">{document.header}</h4>

                <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={docs.employmentContract} />

                <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={docs.quittingOrRedundancyWarning} />

                <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={docs.navDayMoneyLetter} />

                <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={docs.paymentOverviewFromNav} />

            </div>
            }
        </>
    )
}



