import React, { useEffect } from 'react';
import FormNavigation from '../components/FormNavigation';

export default function SetDamageType({ updateFormData, DamageType = "", formValid, handleStages, updateCurrentState, formText }) {


    useEffect(() => {
        updateCurrentState(1);
    })

    const { formType } = formText;


    return (
        <>
            {formType && <div>
                <p className="question-header required">{formType.label}</p>
                {formType.damageTypes.map((damagetype, index) => {
                    if (damagetype !== "empty") {
                        return (
                            <label key={index} className="radio-label">
                                <input name={formType.name} type="radio" required={formType.required === "true" ? true : false} value={damagetype} onChange={updateFormData} checked={DamageType === damagetype ? true : false} />
                                <span className="radio-symbol">{damagetype}</span>
                            </label>
                        )
                    }
                })}
                <p id={`error-message-${formType.name}`} className="error-message no-display">{formType.error}</p>
            </div>
            }
            <FormNavigation finalStage={3} nextRoute={formValid ? "/forsikring" : "/"} prevRoute="/" currentStage={1} formValid={formValid} handleStages={handleStages} {...formText} />

        </>
    )
}
