
import React, { useState, useEffect } from 'react';
import FileList from '../custom/FileList';
import RadioGroup from '../custom/RadioGroup';

export default function Breakup({ updateFormData, formData, formType, breakup, formFiles }) {

    const { marriage, cohabitant } = breakup;
    const { extra, document } = marriage;
    const { extracohabitant, documentcohabitant } = cohabitant;
    const data = formData[formData[formType.name]];

    const [breakupType, setBreakupType] = useState('');

    useEffect(() => {
        if (data && breakup && breakup.whatTypeOfBreakup)
            setBreakupType(data[breakup.whatTypeOfBreakup.name]);
    }, [breakup])

    const handleBreakupType = (value) => {
        setBreakupType(value);
    }


    return (
        <>
            <h2 className="large-header">{breakup.mainHeader}</h2>

            <RadioGroup item={breakup.whatTypeOfBreakup} currentData={data && data[breakup.whatTypeOfBreakup.name]} updateFormData={updateFormData} handleClick={handleBreakupType} />

            {breakupType === breakup.whatTypeOfBreakup.values[0] &&

                <div>
                    <h4 className="normal-header">{marriage.header}</h4>

                    <RadioGroup item={marriage.changedAddress} currentData={data && data[marriage.changedAddress.name]} updateFormData={updateFormData} />

                    {marriage.dateOfOfficialChangeAddress.show !== "false" && data[marriage.changedAddress.name] === "Ja" && <>
                        <label className="input-label">
                            <span className={`${marriage.dateOfOfficialChangeAddress.required === "true" ? "required" : ""}`}> {marriage.dateOfOfficialChangeAddress.label}</span>
                            <input name={marriage.dateOfOfficialChangeAddress.name} type="date" min={marriage.dateOfOfficialChangeAddress.min ? marriage.dateOfOfficialChangeAddress.min : ""} placeholder="dd.mm.yyyy" value={data && data[marriage.dateOfOfficialChangeAddress.name] ? data[marriage.dateOfOfficialChangeAddress.name] : ""} required={marriage.dateOfOfficialChangeAddress.required === "true" ? true : false} onChange={(event) => { updateFormData(event, marriage.dateOfOfficialChangeAddress.label) }} />
                        </label>
                        <p id={`error-message-${marriage.dateOfOfficialChangeAddress.name}`} className="error-message no-display">{marriage.dateOfOfficialChangeAddress.error}</p>
                    </>
                    }

                    {marriage.dateOfSeperationRequest.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${marriage.dateOfSeperationRequest.required === "true" ? "required" : ""}`}> {marriage.dateOfSeperationRequest.label}</span>
                            <input name={marriage.dateOfSeperationRequest.name} type="date" min={marriage.dateOfSeperationRequest.min ? marriage.dateOfSeperationRequest.min : ""} placeholder="dd.mm.yyyy" value={data && data[marriage.dateOfSeperationRequest.name] ? data[marriage.dateOfSeperationRequest.name] : ""} required={marriage.dateOfSeperationRequest.required === "true" ? true : false} onChange={(event) => { updateFormData(event, marriage.dateOfSeperationRequest.label) }} />
                        </label>
                        <p id={`error-message-${marriage.dateOfSeperationRequest.name}`} className="error-message no-display">{marriage.dateOfSeperationRequest.error}</p>
                    </>}

                    <h4 className="normal-header">{extra.header}</h4>

                    <RadioGroup item={extra.earlierCompensation} currentData={data && data[extra.earlierCompensation.name]} updateFormData={updateFormData} />

                    <h4 className="normal-header">{document.header}</h4>

                    <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.seperationPaper} />

                    <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.changeOfficialAddressPaper} />

                </div>
            }



            {
                breakupType === breakup.whatTypeOfBreakup.values[1] &&
                <div>
                    <h4 className="normal-header">{cohabitant.header}</h4>

                    <RadioGroup item={cohabitant.cohabitantChangedAddress} currentData={data && data[cohabitant.cohabitantChangedAddress.name]} updateFormData={updateFormData} />

                    {cohabitant.cohabitantDateOfOfficialChangeAddress.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${cohabitant.cohabitantDateOfOfficialChangeAddress.required === "true" ? "required" : ""}`}> {cohabitant.cohabitantDateOfOfficialChangeAddress.label}</span>
                            <input name={cohabitant.cohabitantDateOfOfficialChangeAddress.name} type="date" min={cohabitant.cohabitantDateOfOfficialChangeAddress.min ? cohabitant.cohabitantDateOfOfficialChangeAddress.min : ""} placeholder="dd.mm.yyyy" value={data && data[cohabitant.cohabitantDateOfOfficialChangeAddress.name] ? data[cohabitant.cohabitantDateOfOfficialChangeAddress.name] : ""} required={cohabitant.cohabitantDateOfOfficialChangeAddress.required === "true" ? true : false} onChange={(event) => { updateFormData(event, cohabitant.cohabitantDateOfOfficialChangeAddress.label) }} />
                        </label>
                        <p id={`error-message-${cohabitant.cohabitantDateOfOfficialChangeAddress.name}`} className="error-message no-display">{cohabitant.cohabitantDateOfOfficialChangeAddress.error}</p>

                    </>}
                    <RadioGroup item={cohabitant.partnerChangedAddress} currentData={data && data[cohabitant.partnerChangedAddress.name]} updateFormData={updateFormData} />


                    {cohabitant.datePartnerChangedAddress.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${cohabitant.datePartnerChangedAddress.required === "true" ? "required" : ""}`}> {cohabitant.datePartnerChangedAddress.label}</span>
                            <input name={cohabitant.datePartnerChangedAddress.name} type="date" min={cohabitant.datePartnerChangedAddress.min ? cohabitant.datePartnerChangedAddress.min : ""} placeholder="dd.mm.yyyy" value={data && data[cohabitant.datePartnerChangedAddress.name] ? data[cohabitant.datePartnerChangedAddress.name] : ""} required={cohabitant.datePartnerChangedAddress.required === "true" ? true : false} onChange={(event) => { updateFormData(event, cohabitant.datePartnerChangedAddress.label) }} />
                        </label>
                        <p id={`error-message-${cohabitant.datePartnerChangedAddress.name}`} className="error-message no-display">{cohabitant.datePartnerChangedAddress.error}</p>
                    </>}

                    <h4 className="normal-header">{extracohabitant.header}</h4>

                    <RadioGroup item={extracohabitant.cohabitantEarlierCompensation} currentData={data && data[extracohabitant.cohabitantEarlierCompensation.name]} updateFormData={updateFormData} />


                    <h4 className="normal-header">{documentcohabitant.header}</h4>

                    <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={documentcohabitant.changeOfficialAddress} />

                    <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={documentcohabitant.earlierAddress} />

                    <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={documentcohabitant.commonChildren} />
                    {/* <h5 className="small-header">Dokumentasjon på felles barn med tidligere samboer</h5>
                <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} multiple="false" name="felles-barn-med-tidligere-samboer" /> */}


                </div>
            }
        </>
    )
}





