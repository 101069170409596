export default function fbfibreakup(caseNumber) {

    return `
    <html>

    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Hei,</span></p>

    <br />

    <p style='margin:0cm'><span lang=DA style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Olemme vastaanottaneet korvaushakemuksesi, jota käsittelemme vahinkoviitteellä ${caseNumber}</span></p>
    
    <br />
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Voidaksemme
    jatkaa korvauskäsittelya, vahinkoilmoitukseen on avioerotilanteessa liitettävä
    seuraavat asiakirjat:    </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    jäljennös käräjäoikeuden avioerotuomiosta;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    digi- ja väestötietoviraston (DVV) antama todistus, josta käy ilmi sinun ja
    entisen puolisosi erilliset osoitteet ja se, mistä lähtien olette olleet
    kirjoilla näissä osoitteissa; </span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    digi- ja väestötietoviraston (DVV) antama todistus yhteisistä lapsistanne.</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'> Voidaksemme
    jatkaa korvauskäsittelya, vahinkoilmoitukseen on asumuserotilanteessa
    liitettävä seuraavat asiakirjat:    </span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    digi- ja väestötietoviraston (DVV) antama ote, jonka mukaan sinä ja entinen
    avopuolisosi olette olleet kirjoilla samassa osoitteessa vähintään viisi (5)
    vuotta ennen asumuseroa;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    digi- ja väestötietoviraston (DVV) antama ote, josta käy ilmi sinun ja entisen
    puolisosi erilliset osoitteet ja se, mistä lähtien olette olleet kirjoilla
    näissä osoitteissa;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-
    digi- ja väestötietoviraston (DVV) antama todistus yhteisistä lapsistanne.</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Jos
    asiakirjoja ei ole ladattu korvaushakemukseen, voit tarvittaessa myöhemmin
    lähettää ne <a href="http://www.tilcrawford.no">www.tilcrawford.no</a> palvelun
    kautta.</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=FI style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Asiakirjat
    voi lähettää myös postitse osoitteella:                                                                                               
    </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>Crawford &amp; Company (Norway)
    AS                                                                                                                     
    </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>Postboks 556
    </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>1327 Lysaker
    </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>Norway</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>Voidaksemme jatkaa korvauskäsittelya, tarvitsemme kaikki
    yllä mainitut asiakirjat.</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>Hakemuksen käsittelyaika on 5 arkipäivää, kun meillä on
    kaikki tarvittavat tiedot ja asiakirjat. Meihin voi ottaa yhteyttä
    sähköpostitse: <a href="mailto:firmapost@crawco.no">firmapost@crawco.no</a>.
    Viittaa aina yllä olevaan vahinkoviitteeseen.</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><i><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
    color:#202124;background:#F8F9FA'>Crawford &amp; Company (Norja) AS on
    henkilötietojen käsittelijä, joka käsittelee korvauksenhakijan henkilötietoja
    ilmoitettuun vahinkoasiaan liittyvän vakuutuksenantajan lukuun.
    Vakuutuksenantaja on AmTrust International Underwriters Limited, 40 Westland
    Row, Dublin 2, Irlanti. Henkilötietojen käsittelyn tarkoituksena on
    vakuutussopimuksen ja -korvausten hallinnointi. Korvauksenhakijalla on oikeus
    tarkastaa mitä häntä koskevia henkilötietoja käsitellään ja oikeus
    virheellisten tietojen korjaamiseen. Henkilötiedot ovat luottamuksellisia ja ne
    poistetaan vanhentumisaikojen mukaisesti. Henkilötunnuksen rekisteröinti on
    välttämätöntä oikean tunnistamisen, julkisten rekistereiden tarkistamisen ja
    viranomaisille oikean raportoinnin ylläpitämiseksi. Crawford &amp; Company
    (Norja) AS: n vaatimustenmukaisuudesta vastaavaan virkailijaan voi ottaa
    yhteyttä osoitteella compliance@crawco.no</span></i></p>
    
    <p class=MsoNormal>&nbsp;</p>
    
    </div>
    
    </body>
    
    </html>
    

`}