import crawfordlogo from '../assets/crawfordlogo.png'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Navigation({ finalStage, handleStages, formValid, currentStage, navigationTitles }) {

    let routeInsurance = "";
    let routeReceipt = "";
    let routeInsuranceClass = "";
    let routeReceiptClass = "";




    switch (currentStage) {
        case 1:
            if (formValid) {
                routeInsurance = "/forsikring";
                routeInsuranceClass = "ready";
            } else {
                routeReceipt = "";
                routeInsuranceClass = "no-pointer-events"
            }

            routeReceiptClass = "no-pointer-events"
            break;

        case 2:
            routeInsurance = "/forsikring";
            if (formValid) {
                routeReceipt = "/kvittering";
                routeReceiptClass = "ready"
            } else {
                routeReceipt = "/forsikring";
                routeReceiptClass = "no-pointer-events"
            }
            routeInsuranceClass = "";
            break;

        case finalStage:
            routeInsurance = "/forsikring";
            routeReceipt = "/kvittering";
            routeReceiptClass = ""
            routeReceiptClass = ""
            break;

        default:
            break;
    }
    return (
        <>
            {navigationTitles && <div>
                <header className="">
                    <Link to="/" >
                        <img src='logo.png' className="App-logo" alt="logo" />
                    </Link>
                </header>

                <nav className="main-navigation">
                    <ul>
                        <li><Link className={`active ${currentStage === 1 ? "current-active" : ""}`} to="/" onClick={handleStages} >{navigationTitles[0]}</Link></li>
                        <li><Link aria-disabled={routeInsurance !== "/forsikring" ? true : false} className={`${routeInsuranceClass} ${currentStage === 2 ? "current-active" : ""} ${currentStage === 2 || currentStage === 3 ? "active" : ""}`} to={routeInsurance} onClick={handleStages} >{navigationTitles[1]}</Link></li>
                        <li><Link aria-disabled={routeReceipt !== "/kvittering" ? true : false} className={`${routeReceiptClass} ${currentStage === 3 ? "active current-active" : ""}`} to={routeReceipt} onClick={handleStages} >{navigationTitles[2]}</Link></li>
                    </ul>
                </nav>
            </div>
            }
        </>

    )
}
