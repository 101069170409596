
export async function getToken(urlSession) {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/Session/${urlSession}`, {
        'method': 'Post',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => { return data });

    return result;
}


export async function getCaseNumber(token) {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/Case/${encodeURIComponent(token)}`, {
        'method': 'Post',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(data => { return data });

    return result;
}


export async function postData(token, body) {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/Post/${encodeURIComponent(token)}`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => response.json()).then(data => { return data })

    return result;
}



export async function postAttchment(token, attachment) {

    let formData = new FormData();
    formData.append("attachment", attachment, attachment.name)
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/Attachment/${encodeURIComponent(token)}`, {

        body: formData,
        method: 'POST'

    }).then(response => response.json()).then(data => { return data })

    return result;
}


export async function getReceipt(token, body) {
    const result = await fetch(`${process.env.REACT_APP_API_SERVICE}/Receipt/${encodeURIComponent(token)}`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },

    }).then(response => response.json()).then(data => { return data })

    return result;
}