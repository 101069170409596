import React from 'react';
import { Link } from 'react-router-dom';

export default function FormNavigation({ nextRoute, prevRoute, finalStage, currentStage, handleStages, buttons, formError }) {


    return (
        <>
            {buttons && formError && <>
                <p className="form-error lead no-display" aria-live="assertive">{formError}</p>
                <div className="form-navigation">
                    <Link className="navigation-button" style={{ display: currentStage < 2 ? "none" : "block" }} onClick={handleStages} to={prevRoute} id="previousButton">{buttons.previous}</Link>
                    <Link className="navigation-button" style={{ display: currentStage === finalStage ? "none" : "block" }} onClick={handleStages} to={nextRoute} id="nextButton" >{buttons.next}</Link>
                    <input type="submit" className="navigation-button" style={{ display: currentStage === finalStage ? "block" : "none" }} value={buttons.submit} />

                </div>
            </>
            }
        </>
    )
}
