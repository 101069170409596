import React, { useEffect } from 'react';
import FormNavigation from '../components/FormNavigation';

export default function Receipt({ updateFormData, formValid, handleStages, updateCurrentState, formData, formText, receiptInfo }) {


    useEffect(() => {
        updateCurrentState(3);
        // console.log(formText.receipt.confirmation.required);
    })

    const { receipt } = formText;
    // const data = formData[formData[formText.formType.name]] ? formData[formData[formText.formType.name]] : [];
    const { DamageType } = receiptInfo;
    const result = Object.entries(receiptInfo[DamageType]);

    const Items = () => {
        return (
            <div className="receiptInfo">
                {result.map((item, index) => {
                    // let title = item[0];
                    let name = item[1].name;
                    let value = item[1].value;
                    return (
                        <p key={index}><span className="question-header question-header__initial question-header__black">{name}:</span> {value}</p>
                    )
                })}
            </div>
        )

    }

    return (
        <>
            {(formData === null || formData === undefined) && <a className="lead" href="/">Tilbake til hjemside</a>}

            {/* <div style={{ display: formSubmitted ? "block" : "none" }} >
                    <h3 className="medium-header">{receipt.submitMessage}</h3>
                </div> */}

            {formData &&

                <div>
                    <h2 className="large-header">{receipt.header}</h2>
                    <p className="lead">{receipt.check}</p>

                    <h4 className="normal-header">{receipt.typeHeader}</h4>
                    <p><span className="question-header question-header__initial question-header__black">{receipt.typeBody}:</span> {DamageType}</p>

                    {/* <h4 className="normal-header">Forsikrede</h4> */}

                    <h4 className="normal-header">{receipt.infoHeader}</h4>
                    <Items />

                    <label className={`checkbox-label ${receipt.confirmation.show === "false" ? "no-display" : ""}`}>
                        <input type="checkbox" name={receipt.confirmation.name} onChange={event => { updateFormData(event) }} required={receipt.confirmation.required === "true" ? true : false} />
                        <span className="checkbox-symbol">{receipt.confirmation.label}</span>
                    </label>
                    <p id={`error-message-${receipt.confirmation.name}`} className="error-message no-display">{receipt.confirmation.error}</p>


                    <label className={`checkbox-label ${receipt.consent.show === "false" ? "no-display" : ""}`}>
                        <input type="checkbox" name={receipt.consent.name} onChange={event => { updateFormData(event) }} required={receipt.consent.required === "true" ? true : false} />
                        <span className="checkbox-symbol">{receipt.consent.label}</span>
                    </label>
                    <p id={`error-message-${receipt.consent.name}`} className="error-message no-display">{receipt.consent.error}</p>

                    <FormNavigation finalStage={3} nextRoute="/forsikring" prevRoute="/forsikring" currentStage={3} formValid={formValid} handleStages={handleStages} {...formText} />
                </div>

            }
        </>
    )
}

