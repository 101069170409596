import norsk from '../data/norsk.json';
import fbdk from '../data/fbdk.json';
import fbfi from '../data/fbfi.json';
import fbno from '../data/fbno.json';
import fbse from '../data/fbse.json';
import kkdk from '../data/kkdk.json';
import kkfi from '../data/kkfi.json';
import kkno from '../data/kkno.json';
import kkse from '../data/kkse.json';

import dvdk from '../data/dvdk.json';
import dvfi from '../data/dvfi.json';
import dvno from '../data/dvno.json';
import rlno from '../data/rlno.json';
import dvse from '../data/dvse.json';
import fbfs from '../data/fbfs.json';
import kkfs from '../data/kkfs.json';
import dvfs from '../data/dvfs.json';

import dk from '../data/reply/rfbdk.json';
import fi from '../data/reply/rfbfi.json';
import no from '../data/reply/rfbno.json';
import se from '../data/reply/rfbse.json';

import dvdksickness from '../data/reply/dvdksickness.js';
import dvdkwork from '../data/reply/dvdkwork';

import dvfisickness from '../data/reply/dvfisickness';
import dvfiwork from '../data/reply/dvfiwork';

import dvnosickness from '../data/reply/dvnosickness';
import dvnowork from '../data/reply/dvnowork';

import dvsesickness from '../data/reply/dvsesickness';
import dvsework from '../data/reply/dvsework';



import fbdkbreakup from '../data/reply/fbdkbreakup.js';
import fbdksickness from '../data/reply/fbdksickness.js';
import fbdkwork from '../data/reply/fbdkwork';

import fbfibreakup from '../data/reply/fbfibreakup';
import fbfisickness from '../data/reply/fbfisickness';
import fbfiwork from '../data/reply/fbfiwork';

import fbnobreakup from '../data/reply/fbnobreakup';
import fbnosickness from '../data/reply/fbnosickness';
import fbnowork from '../data/reply/fbnowork';

import fbsebreakup from '../data/reply/fbsebreakup';
import fbsesickness from '../data/reply/fbsesickness';
import fbsework from '../data/reply/fbsework';

export default function jsonSource() {

    return {
        "norsk": norsk, "fbdk": fbdk, "fbfi": fbfi, "fbno": fbno, "fbse": fbse, "kkdk": kkdk,
        "kkfi": kkfi, "kkno": kkno, "kkse": kkse,
        "dvdk": dvdk, "dvfi": dvfi, "dvno": dvno, "rlno": rlno, "dvse": dvse, "fbfs": fbfs, "kkfs": kkfs, "dvfs": dvfs, "dk": dk,
        "fi": fi, "no": no, "se": se, dvdksickness, dvdkwork,
        dvfisickness, dvfiwork, dvnosickness, dvnowork, dvsesickness, dvsework,
        fbdkbreakup, fbdksickness, fbdkwork, fbfibreakup,
        fbfisickness, fbfiwork, fbnobreakup, fbnosickness, fbnowork, fbsebreakup, fbsesickness, fbsework
    }

}