export default function dvnowork(caseNumber) {

    return `
    <html>

    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Hei,</span></p>

    <br />

	<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Din sak er nå registrert med saksnummer ${caseNumber}</span></p>
    
    <br />
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>For at vi skal kunne vurdere saken din, må vi ha følgende
    dokumenter:</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>- Ansettelseskontrakt for arbeidsforholdet</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>- Oppsigelsesvarsel eller permitteringsvarsel som angir årsaken
    til arbeidsledigheten. </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>- Vedtak fra NAV om at du har fått innvilget Dagpenger </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>- Utbetalingsoversikt (som angir navn og personnummer) fra NAV som
    bekrefter utbetaling av Dagpenger for hele perioden du har vært arbeidsledig
    (minimum 30 dager). </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Dersom dokumentene ikke er lastet opp i skademeldingsskjemaet
    eller du skulle ha behov for å ettersende dokumentasjon, kan dette lastes opp
    via </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><a
    href="http://www.tilcrawford.no">www.tilcrawford.no </a></span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Alternativt kan dokumentasjon sendes per post til: </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Crawford &amp; Company (Norway) AS</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Postboks 556</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>1327 Lysaker</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Så snart all nødvendig dokumentasjon er mottatt vil saksbehandler
    foreta en vurdering av saken. </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Vi har en behandlingstid på 5 virkedager fra all nødvendig
    dokumentasjon som listet opp ovenfor er mottatt. Ved behov kan du kontakte oss
    på e - post: </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><a
    href="mailto:firmapost@crawco.no">firmapost@crawco.no</a><span
    style='color:black'>. Husk saksnummer!</span></span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><i><span style='font-size:8.0pt;font-family:"Calibri",sans-serif;
    color:black'>Crawford &amp; Company (Norway) AS er databehandler og behandler
    personopplysninger om deg p å vegne av behandlingsansvarlige i forbindelse med
    melding om skade. Behandlingsansvarlig er AmTrust International Underwriters
    Limited, 40 Westland Row, Dublin 2, Irland. Formålet med behandlingen er å
    ivareta forsikringsavtalen og foreta korrekte oppgjør. Du har innsynsrett i
    dine personopplysninger og har rett til å få uriktige opplysninger korrigert.
    Personopplysningene er taushetsbelagt og vil bli slettet etter reglene om
    foreldelse. Registrering av fødselsnummer er nødvendig for sikker
    identifikasjon, sjekk mot offentlige registre og for å ivareta korrekt
    rapportering til offentlige myndigheter. Personvernombud i Crawford &amp;
    Company (Norway) AS kan kontaktes på </span></i><span style='font-size:8.0pt;
    font-family:"Calibri",sans-serif'><a href="mailto:compliance@crawco.no"><i>compliance@crawco.no</i></a><i><span
    style='color:black'>. </span></i></span></p>
    
    <p class=MsoNormal>&nbsp;</p>
    
    </div>
    
    </body>
    
    </html>
    
`
}