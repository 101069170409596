import React from 'react';
import { useState } from 'react';
import FileList from '../custom/FileList';
import RadioGroup from '../custom/RadioGroup';

export default function Sickness({ updateFormData, formData, formType, sickness, formFiles }) {


    const { employment, extra, document } = sickness;
    const data = formData[formData[formType.name]];


    const [fixedEmployee, setfixedEmployee] = useState('');
    const [earlierCompensation, setearlierCompensation] = useState('');
    const [compensationFor, setCompensationFor] = useState('');
    const [stillSickLeave, setStillSickLeave] = useState('');
    const handleFixedEmployee = (value) => {
        setfixedEmployee(value);
    }
    const showEarlierCompensation = (value) => {
        setearlierCompensation(value);
    }
    const handleCompensationFor = (value) => {
        setCompensationFor(value);
    }
    const handleStillSickLeave = (value) => {
        setStillSickLeave(value);
    }
    return (
        <>
            <h2 className="large-header">{sickness.mainHeader}</h2>

            {sickness.sicknessReason.show !== "false" && <>
                <label className="input-label">
                    <span className={`${sickness.sicknessReason.required === "true" ? "required" : ""}`}> {sickness.sicknessReason.label}</span>
                    <input name={sickness.sicknessReason.name} type="text" value={data && data[sickness.sicknessReason.name] ? data[sickness.sicknessReason.name] : ""} required={sickness.sicknessReason.required === "true" ? true : false} onChange={(event) => { updateFormData(event, sickness.sicknessReason.label) }} />
                </label>
                <p id={`error-message-${sickness.sicknessReason.name}`} className="error-message no-display">{sickness.sicknessReason.error}</p>
            </>}

            {sickness.sicknessDate.show !== "false" && <>
                <label className="input-label">
                    <span className={`${sickness.sicknessDate.required === "true" ? "required" : ""}`}> {sickness.sicknessDate.label}</span>
                    <input name={sickness.sicknessDate.name} type="date" min={sickness.sicknessDate.min ? sickness.sicknessDate.min : ""} placeholder="dd.mm.yyyy" value={data && data[sickness.sicknessDate.name] ? data[sickness.sicknessDate.name] : ""} required={sickness.sicknessDate.required === "true" ? true : false} onChange={(event) => { updateFormData(event, sickness.sicknessDate.label) }} />
                </label>
                <p id={`error-message-${sickness.sicknessDate.name}`} className="error-message no-display">{sickness.sicknessDate.error}</p>
            </>}

            <RadioGroup item={sickness.registrertAAP} currentData={data && data[sickness.registrertAAP.name]} updateFormData={updateFormData} />

            {sickness.dataOfFirstDiagnose.show !== "false" && <>
                <label className="input-label">
                    <span className={`${sickness.dataOfFirstDiagnose.required === "true" ? "required" : ""}`}> {sickness.dataOfFirstDiagnose.label}</span>
                    <input name={sickness.dataOfFirstDiagnose.name} type="date" min={sickness.dataOfFirstDiagnose.min ? sickness.dataOfFirstDiagnose.min : ""} placeholder="dd.mm.yyyy" value={data && data[sickness.dataOfFirstDiagnose.name] ? data[sickness.dataOfFirstDiagnose.name] : ""} required={sickness.dataOfFirstDiagnose.required === "true" ? true : false} onChange={(event) => { updateFormData(event, sickness.dataOfFirstDiagnose.label) }} />
                </label>
                <p id={`error-message-${sickness.dataOfFirstDiagnose.name}`} className="error-message no-display">{sickness.dataOfFirstDiagnose.error}</p>
            </>}

            <RadioGroup item={sickness.diagnoseEarlier} currentData={data && data[sickness.diagnoseEarlier.name]} updateFormData={updateFormData} />

            {sickness.whichPeriodYouWereSick.show !== "false" && <>
                <label className="input-label">
                    <span className={`${sickness.whichPeriodYouWereSick.required === "true" ? "required" : ""}`}> {sickness.whichPeriodYouWereSick.label}</span>
                    <input name={sickness.whichPeriodYouWereSick.name} type="date" min={sickness.whichPeriodYouWereSick.min ? sickness.whichPeriodYouWereSick.min : ""} placeholder="dd.mm.yyyy" value={data && data[sickness.whichPeriodYouWereSick.name] ? data[sickness.whichPeriodYouWereSick.name] : ""} required={sickness.whichPeriodYouWereSick.required === "true" ? true : false} onChange={(event) => { updateFormData(event, sickness.whichPeriodYouWereSick.label) }} />
                </label>
                <p id={`error-message-${sickness.whichPeriodYouWereSick.name}`} className="error-message no-display">{sickness.whichPeriodYouWereSick.error}</p>
            </>}

            <RadioGroup item={sickness.stillSickLeave} currentData={data && data[sickness.stillSickLeave.name]} updateFormData={updateFormData} handleClick={handleStillSickLeave} />

            {
                stillSickLeave === "Nei" && <div>
                    {sickness.datenotstillsick.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${sickness.datenotstillsick.required === "true" ? "required" : ""}`}> {sickness.datenotstillsick.label}</span>
                            <input name={sickness.datenotstillsick.name} type="date" placeholder="dd.mm.yyyy" value={data && data[sickness.datenotstillsick.name] ? data[sickness.datenotstillsick.name] : ""} required={sickness.datenotstillsick.required === "true" ? true : false} onChange={(event) => { updateFormData(event, sickness.datenotstillsick.label) }} />
                        </label>
                        <p id={`error-message-${sickness.datenotstillsick.name}`} className="error-message no-display">{sickness.datenotstillsick.label}</p>
                    </>}</div>
            }

            <RadioGroup item={sickness.treatmentPsycology} currentData={data && data[sickness.treatmentPsycology.name]} updateFormData={updateFormData} />

            <h4 className="normal-header">{employment.header}</h4>

            {employment.employerName.show !== "false" && <>
                <label className="input-label">
                    <span className={`${employment.employerName.required === "true" ? "required" : ""}`}> {employment.employerName.label}</span>
                    <input name={employment.employerName.name} type="text" value={data && data[employment.employerName.name] ? data[employment.employerName.name] : ""} required={employment.employerName.required === "true" ? true : false} onChange={(event) => { updateFormData(event, employment.employerName.label) }} />
                </label>
                <p id={`error-message-${employment.employerName.name}`} className="error-message no-display">{employment.employerName.error}</p>
            </>}

            {employment.dateForEmployment.show !== "false" && <>
                <label className="input-label">
                    <span className={`${employment.dateForEmployment.required === "true" ? "required" : ""}`}> {employment.dateForEmployment.label}</span>
                    <input name={employment.dateForEmployment.name} type="date" min={employment.dateForEmployment.min ? employment.dateForEmployment.min : ""} placeholder="dd.mm.yyyy" value={data && data[employment.dateForEmployment.name] ? data[employment.dateForEmployment.name] : ""} required={employment.dateForEmployment.required === "true" ? true : false} onChange={(event) => { updateFormData(event, employment.dateForEmployment.label) }} />
                </label>
                <p id={`error-message-${employment.dateForEmployment.name}`} className="error-message no-display">{employment.dateForEmployment.error}</p>
            </>}

            <RadioGroup item={employment.areYouFixedEmployee} currentData={data && data[employment.areYouFixedEmployee.name]} updateFormData={updateFormData} handleClick={handleFixedEmployee} />

            {
                fixedEmployee === "Ja" &&
                <div >
                    {employment.employmentPercentage.show !== "false" && <>
                        <label className="input-label">
                            <span className={`${employment.employmentPercentage.required === "true" ? "required" : ""}`}> {employment.employmentPercentage.label}</span>
                            <input name={employment.employmentPercentage.name} type="text" value={data && data[employment.employmentPercentage.name] ? data[employment.employmentPercentage.name] : ""} required={employment.employmentPercentage.required === "true" ? true : false} onChange={(event) => { updateFormData(event, employment.employmentPercentage.label) }} />
                        </label>
                        <p id={`error-message-${employment.employmentPercentage.name}`} className="error-message no-display">{employment.employmentPercentage.label}</p>
                    </>}
                </div>
            }

            <h4 className="normal-header">{extra.header}</h4>

            <RadioGroup item={extra.earlierCompensationInsurance} currentData={data && data[extra.earlierCompensationInsurance.name]} updateFormData={updateFormData} handleClick={showEarlierCompensation} />

            {
                earlierCompensation === "Ja" &&
                <div >
                    <RadioGroup item={extra.whatYouGetCompensationFor} currentData={data && data[extra.whatYouGetCompensationFor.name]} updateFormData={updateFormData} handleClick={handleCompensationFor} />

                    {compensationFor === "Arbeidsledighet/permittering" &&
                        <div >
                            <h5 className="small-header">{extra.dateOfUnemployment.header}</h5>

                            {extra.dateOfUnemployment.from.show !== "false" && <>
                                <label className="input-label">
                                    <span className={`${extra.dateOfUnemployment.from.required === "true" ? "required" : ""}`}> {extra.dateOfUnemployment.from.label}</span>
                                    <input name={extra.dateOfUnemployment.from.name} type="date" min={extra.dateOfUnemployment.from.min ? extra.dateOfUnemployment.from.min : ""} placeholder="dd.mm.yyyy" value={data && data[extra.dateOfUnemployment.from.name] ? data[extra.dateOfUnemployment.from.name] : ""} required={extra.dateOfUnemployment.from.required === "true" ? true : false} onChange={(event) => { updateFormData(event, extra.dateOfUnemployment.from.label) }} />
                                </label>
                                <p id={`error-message-${extra.dateOfUnemployment.from.name}`} className="error-message no-display">{extra.dateOfUnemployment.from.label}</p>
                            </>}

                            {extra.dateOfUnemployment.to.show !== "false" && <>
                                <label className="input-label">
                                    <span className={`${extra.dateOfUnemployment.to.required === "true" ? "required" : ""}`}> {extra.dateOfUnemployment.to.label}</span>
                                    <input name={extra.dateOfUnemployment.to.name} type="date" min={extra.dateOfUnemployment.to.min ? extra.dateOfUnemployment.to.min : ""} placeholder="dd.mm.yyyy" value={data && data[extra.dateOfUnemployment.to.name] ? data[extra.dateOfUnemployment.to.name] : ""} required={extra.dateOfUnemployment.to.required === "true" ? true : false} onChange={(event) => { updateFormData(event, extra.dateOfUnemployment.to.label) }} />
                                </label>
                                <p id={`error-message-${extra.dateOfUnemployment.to.name}`} className="error-message no-display">{extra.dateOfUnemployment.to.label}</p>
                            </>}
                        </div>
                    }

                    {compensationFor === "Sykdom" &&
                        <div >
                            <h5 className="small-header">{extra.dateForSicknessPeriod.header}</h5>
                            {extra.dateForSicknessPeriod.from.show !== "false" && <>
                                <label className="input-label">
                                    <span className={`${extra.dateForSicknessPeriod.from.required === "true" ? "required" : ""}`}> {extra.dateForSicknessPeriod.from.label}</span>
                                    <input name={extra.dateForSicknessPeriod.from.name} type="date" min={extra.dateForSicknessPeriod.from.min ? extra.dateForSicknessPeriod.from.min : ""} placeholder="dd.mm.yyyy" value={data && data[extra.dateForSicknessPeriod.from.name] ? data[extra.dateForSicknessPeriod.from.name] : ""} required={extra.dateForSicknessPeriod.from.required === "true" ? true : false} onChange={(event) => { updateFormData(event, extra.dateForSicknessPeriod.from.label) }} />
                                </label>
                                <p id={`error-message-${extra.dateForSicknessPeriod.from.name}`} className="error-message no-display">{extra.dateForSicknessPeriod.from.label}</p>
                            </>}

                            {extra.dateForSicknessPeriod.to.show !== "false" && <>
                                <label className="input-label">
                                    <span className={`${extra.dateForSicknessPeriod.to.required === "true" ? "required" : ""}`}> {extra.dateForSicknessPeriod.to.label}</span>
                                    <input name={extra.dateForSicknessPeriod.to.name} type="date" min={extra.dateForSicknessPeriod.to.min ? extra.dateForSicknessPeriod.to.min : ""} placeholder="dd.mm.yyyy" value={data && data[extra.dateForSicknessPeriod.to.name] ? data[extra.dateForSicknessPeriod.to.name] : ""} required={extra.dateForSicknessPeriod.to.required === "true" ? true : false} onChange={(event) => { updateFormData(event, extra.dateForSicknessPeriod.to.label) }} />
                                </label>
                                <p id={`error-message-${extra.dateForSicknessPeriod.to.name}`} className="error-message no-display">{extra.dateForSicknessPeriod.to.label}</p>
                            </>}
                        </div>
                    }

                </div>

            }

            {extra.extraInformation.show !== "false" && <>
                <label className="input-label">
                    <span className={`${extra.extraInformation.required === "true" ? "required" : ""}`}> {extra.extraInformation.label}</span>
                    <textarea name={extra.extraInformation.name} value={data && data[extra.extraInformation.name] ? data[extra.extraInformation.name] : ""} required={extra.extraInformation.required === "true" ? true : false} onChange={(event) => { updateFormData(event, extra.extraInformation.label) }}></textarea>
                </label>
                <p id={`error-message-${extra.extraInformation.name}`} className="error-message no-display">{extra.extraInformation.name.label}</p>
            </>}


            <h4 className="normal-header">{document.header}</h4>

            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.sicknessB} />

            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.alternativedoctorcert} />

            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.decisionAAP} />

            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.reportCard} />

            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} item={document.doctorRefrence} />
            {/* <h5 className="small-header">Kopi av henvisning fra din lege til offentlig godkjent psykolog</h5>
            <FileList list={formFiles[formFiles[formType.name]]} updateFormData={updateFormData} /> */}

        </>
    )
}








