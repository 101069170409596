import React from 'react';

export default function Logo() {
    return (
        <header className="header-logo">
            <img src='logo.png' alt="logo" />
        </header>
    )
}




