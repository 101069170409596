export default function fbnobreakup(caseNumber) {

    return `

    <html>

    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Hei,</span></p>
    
    <br />
	<p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Din sak er nå registrert med saksnummer ${caseNumber}</span></p>

    <br />
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>For at vi skal kunne vurdere saken din, må vi
    ha følgende dokumenter ved samlivsbrudd med ektefelle:</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;
    font-family:"Calibri",sans-serif'>-Kopi av separasjonssøknad</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;
    font-family:"Calibri",sans-serif'>-Kopi av dokumentasjon på endring i
    folkeregistrert adresse</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>For at vi skal kunne vurdere saken din, må vi
    ha følgende dokumenter ved samlivsbrudd med samboer:</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;
    font-family:"Calibri",sans-serif'>-Dokumentasjon på endring i folkeregistrert
    adresse</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-Dokumentasjon
    på at tidligere folkeregistrerte adresse med samboer varte i mer enn 5 år</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-Dokumentasjon
    på felles barn med tidligere samboer</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'> </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Dersom dokumentene ikke er lastet opp i
    skademeldingsskjemaet eller du skulle ha behov for å ettersende dokumentasjon,
    kan dette lastes opp via <a href="http://www.tilcrawford.no">www.tilcrawford.no</a>
    </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'> </span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Alternativt kan dokumentasjon sendes per post
    til:</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Crawford &amp; Company (Norway) AS</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>Postboks 556</span></p>
    
    <p style='margin:0cm'><span lang=EN-US style='font-size:11.0pt;font-family:
    "Calibri",sans-serif;color:black'>1327 Lysaker</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Så snart all nødvendig dokumentasjon er mottatt vil saksbehandler
    foreta en vurdering av saken. </span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></p>
    
    <p style='margin:0cm'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>Vi har en behandlingstid på 5 virkedager fra all nødvendig
    dokumentasjon som listet opp ovenfor er mottatt. Ved behov kan du kontakte oss
    på e - post: </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><a
    href="mailto:firmapost@crawco.no">firmapost@crawco.no</a><span
    style='color:black'>. Husk saksnummer!</span></span></p>
    
    <p style='margin:0cm'><i><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
    color:black'>&nbsp;</span></i></p>
    
    <p style='margin:0cm'><i><span style='font-size:8.0pt;font-family:"Calibri",sans-serif;
    color:black'>Crawford &amp; Company (Norway) AS er databehandler og behandler
    personopplysninger om deg p å vegne av behandlingsansvarlige i forbindelse med
    melding om skade. Behandlingsansvarlig er AmTrust International Underwriters
    Limited, 40 Westland Row, Dublin 2, Irland. Formålet med behandlingen er å
    ivareta forsikringsavtalen og foreta korrekte oppgjør. Du har innsynsrett i
    dine personopplysninger og har rett til å få uriktige opplysninger korrigert.
    Personopplysningene er taushetsbelagt og vil bli slettet etter reglene om
    foreldelse. Registrering av fødselsnummer er nødvendig for sikker identifikasjon,
    sjekk mot offentlige registre og for å ivareta korrekt rapportering til
    offentlige myndigheter. </span></i><i><span lang=EN-US style='font-size:8.0pt;
    font-family:"Calibri",sans-serif;color:black'>Personvernombud i Crawford &amp;
    Company (Norway) AS kan kontaktes på </span></i><span style='font-size:8.0pt;
    font-family:"Calibri",sans-serif'><a href="mailto:compliance@crawco.no"><i><span
    lang=EN-US>compliance@crawco.no</span></i></a></span><i><span lang=EN-US
    style='font-size:8.0pt;font-family:"Calibri",sans-serif;color:black'>. </span></i></p>
    
    <p class=MsoNormal>&nbsp;</p>
    
    </div>
    
    </body>
    
    </html>
    
`
}